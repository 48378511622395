import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './navbar.css';
import Image from 'react-bootstrap/Image'
import { useConfig } from '../../context/configContext';
import NavLinks from '../NavServices/NavLinks';

const Topbar = () => {
  const config = useConfig();
  
  return (
    <Navbar className="navbar-dark" expand="lg" sticky='top'>
      <Container className="flex-sb">
        <Navbar.Brand className='nav-text' href="#/">
          <Image src={`${config?.images?.basePath}${config?.images?.whiteLogo}`} className="navbar__image" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav"/>
        <Navbar.Collapse id="basic-navbar-nav">
          <div id="spacer"></div>
          <NavLinks />
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Topbar;