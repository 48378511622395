import './directorCard.css';
import { useConfig } from '../../context/configContext';
import Card from 'react-bootstrap/Card';
import parse from 'html-react-parser';

const DirectorCard = () => {
  const config = useConfig();
  return(
    <Card className="directorCard__card">
      <Card.Img variant="top" src={`${config?.images?.basePath}${config?.images?.director}`} />
      <Card.Body>
        <Card.Title>
          <h3>{ config?.director?.name}</h3>
        </Card.Title>
        <Card.Text className="directorCard__cardText" >
          { parse(config?.director?.bio ? config?.director?.bio : "") }
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default DirectorCard;