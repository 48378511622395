import './contact.css';
import TopbarPages from '../../components/NavbarPages/NavbarPages';
import FooterPages from '../../components/FooterPages/FooterPages';
import Container from 'react-bootstrap/esm/Container';
import Banner from '../../components/Banner/Banner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import ContactForm from '../../components/ContactForm/ContactForm';
import ContactAddress from '../../components/ContactInformation/ContactAddress';
import ContactPhone from '../../components/ContactInformation/ContactPhone';
import ContactFax from '../../components/ContactInformation/ContactFax';
import ContactEmail from '../../components/ContactInformation/ContactEmail';
import PropBar from '../../components/PropBar/ProbBar';
import ContactModal from '../../components/ContactModal/ContactModal';
import { useEffect, useState } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Contact = () => {
  const [show, setShow] = useState(false);
  const [ modelError, setModalError ] = useState(false);
  const config = useConfig();
  const { width } = useWindowDimensions();

  const handleClose = () => setShow(false);
  const handleShow = (error) => {
    if(error){
      setShow(true);
      setModalError(true);
    }
    else{
      setShow(true);
      setModalError(false);
    }
  }

  useEffect(()=>{
    document.title = "Lighthill Law - Contact";
  },[])

  return(
    <div className='contact'>
      <TopbarPages />   
      <Container>
        <Banner title={'Contact Us'} />
        <Row
          xs={1}
          sm={1}
          md={1}
          lg={2}
          className="contact__firstRow"
        >
          <Col className="contact__firstCol">
            <ContactForm handleShow={handleShow} />
          </Col>
          <Col className="contact__secondCol">
            <h2>If you have any legal concerns or questions, please do not hesitate to contact us for assistance.</h2>
            <Container className="contact__margin-top" >
              {
                config?.address?.postcode
                &&
                <Row className='contact__infoRows'>
                  <ContactAddress />
                </Row>
              }
              {
                config?.phoneNumber
                &&
                <Row className='contact__infoRows'>
                  <ContactPhone />
                </Row>
              }
              {
                config?.fax
                &&
                <Row className='contact__infoRows'>
                  <ContactFax />
                </Row>
              }
              {
                config?.email
                &&
                <Row className='contact__infoRows'>
                  <ContactEmail />
                </Row>
              }
            </Container>
          </Col>
        </Row>
        <Row className="contact__secondRow" style={width < 800 ? {width:"103vw"} : {width:"101vw"}}>
          <FooterPages />
          <PropBar />
        </Row>
      </Container>
      <ContactModal show={show} handleClose={handleClose} error={modelError}/>
    </div>
  )
}

export default Contact;