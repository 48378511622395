import './contactInformation.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import Container from 'react-bootstrap/esm/Container';
import ContactPhoneSharpIcon from '@mui/icons-material/ContactPhoneSharp';

const ContactPhone = () => {
  const config = useConfig();
  return(
    <Container>
      <Row>
        <Col
          lg={2}
          md={2}
          sm={2}
          xl={2}
          xs={2}
          xxl={2}
        >
          <ContactPhoneSharpIcon fontSize='large' className='contactInformation__icon' />
        </Col>
        <Col>
          <Container>
            <Row>
              { config?.phoneNumber }
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactPhone;