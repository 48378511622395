import './contactInformation.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import Container from 'react-bootstrap/esm/Container';
import BusinessSharpIcon from '@mui/icons-material/BusinessSharp';
import { useEffect, useState } from 'react';

const ContactAddress = ({type}) => {
  const config = useConfig();
  const [ addressConfig, setAddressConfig ] = useState('');
  
  useEffect(()=>{
    config?.address?.unit
    ?
    setAddressConfig(`${config?.address?.unit} / ${config?.address?.number} ${config?.address?.street} ${config?.address?.streetType }, ${config?.address?.city }`)
    :
    config?.address?.street
    ?
    setAddressConfig(`${config?.address?.number} ${config?.address?.street} ${config?.address?.streetType }, ${config?.address?.city }`)
    :
    config?.address?.number
    &&
    setAddressConfig(`${config?.address?.number}, ${config?.address?.city }`)
  },[config?.address?.unit, config?.address?.street, config?.address?.number])

  return(
    <>
    {
      type === 'footer'
      ?
      <Container>
        <Row className="contactInformation__address-footer">
          { addressConfig }
        </Row>
        <Row className="contactInformation__address-footer">
          { `${config?.address?.state}, ${config?.address?.postcode}` }
        </Row>
      </Container>
      :
      <Container>
        <Row>
          <Col
            lg={2}
            md={2}
            sm={2}
            xl={2}
            xs={2}
            xxl={2}
          >
            <BusinessSharpIcon fontSize='large' className='contactInformation__icon' />
          </Col>
          <Col>
            <Container>
              <Row>
                { addressConfig }
              </Row>
              <Row>
                { `${config?.address?.state}, ${config?.address?.postcode}` }
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    }
    </>
  )
}

export default ContactAddress;