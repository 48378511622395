import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container"
import Image from "react-bootstrap/esm/Image";
import Row from "react-bootstrap/esm/Row";
import { Link } from "react-router-dom";
import { useConfig } from '../../context/configContext';
import ContactAddress from "../ContactInformation/ContactAddress";
import './footerPages.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const FooterPages = () => {
  const config = useConfig();
  const { height, width } = useWindowDimensions();

  return(
    <div className="footerPages" >
      <Container>
        <Row className="footerPages__row-height">
          <Col>
          </Col>
          <Col className="footerPages__row-info">
            <span><Link className='footer-link' to='/about'>About</Link> | <Link className='footer-link' to='/services/Conveyancing'>Services</Link> | <Link className='footer-link' to='/contact'>Contact</Link> { config?.clientPortal && <>| <Link className='footer-link' to='/client'>Client Portal</Link></>}</span>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row>
          <Col>
          </Col>
          <Col>
            <Image src={`${config?.images?.basePath}${config?.images?.whiteLogo}`} className="footerPages__image" >
            </Image>
          </Col>
          <Col>
          </Col>
        </Row>
        <Row className="footerPages__row-main">
          <Col>
          </Col>
          <Col className="footerPages__col-main">
            <ContactAddress type="footer"/>
          </Col>
          <Col>
          </Col>
        </Row>
      </Container>
    </div>
  )
};

export default FooterPages;