import './banner.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Banner = ({title}) => {
  const config = useConfig();
  const { height, width } = useWindowDimensions();

  return(
    <Row 
      className="banner" 
      style={
        width >= 1200
        ?
        {
          height: '280px',
          backgroundImage: `url(${config?.images?.basePath}${config?.images?.banner})`
        }
        :
        { 
          height: '200px',
          backgroundImage: `url(${config?.images?.basePath}${config?.images?.banner})`
        }
      }>
      <Col lg={{span: 4}}>
        <h1>{ title }</h1>
      </Col>
    </Row>
  )
}

export default Banner;