import { useState, useEffect, createContext } from "react";
import { Route, Routes } from 'react-router';
import { HashRouter as Router } from 'react-router-dom';
import { request } from './requestMethods';
import './App.css';
import About from './pages/about/About';
import Client from './pages/client/Client';
import Contact from './pages/contact/Contact';
import Home from './pages/home/Home';
import Services from './pages/services/Services';
import { ConfigProvider, useConfig } from './context/configContext';

const ConfigContext = createContext()

function App() {
  const [config, setConfig] = useState({});

  useEffect(()=>{
    const getConfig = async () => {
      try {
        const res = await request.get("/website/config.json");
        console.log("%cSuccess Config","color:green;font-size:24px;",res.data)
        setConfig(res.data);
      } catch (err) {
        console.log("%cERROR","color:red;font-size:24px;",err)
      }
    };
    getConfig();
  },[])

  return (
    <div 
      className="App"
      style={{
        "--primary-color": config?.primaryColor,
        "--secondary-color": config?.secondaryColor
      }}
    >
      <ConfigProvider value={config}>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/services/:type" element={<Services />} />
            <Route path="/client" element={<Client />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </Router>
      </ConfigProvider>
    </div>
  );
}

export default App;
