import axios from "axios";
import { BASE_URL } from './config'
import { BASE_URL_HILL } from './config'
import { API_KEY } from './config'

export const request = axios.create({
    baseURL: BASE_URL
});

export const apiRequest = axios.create({
    headers: {
        post: {
            "x-api-key": API_KEY
        }
    },
    baseURL: BASE_URL_HILL
});