import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import './navbarPages.css';
import Image from 'react-bootstrap/Image'
import { useConfig } from '../../context/configContext';
import NavLinks from '../NavServices/NavLinks';

const TopbarPages = () => {
  const config = useConfig();

  return (
    <>
      <Navbar className="navbar-light" expand="lg">
        <Container className="flex-sb">
          <Navbar.Brand className='nav-text' href="#/">
            <Image src={`${config?.images?.basePath}${config?.images?.colorLogo}`} className="navbarPages__image" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav"/>
          <Navbar.Collapse id="basic-navbar-nav">
            <div id="spacer"></div>
            <NavLinks type="pages"/>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="space-bar"></div>
    </>
  );
}

export default TopbarPages;