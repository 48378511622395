import './about.css';
import TopbarPages from '../../components/NavbarPages/NavbarPages';
import FooterPages from '../../components/FooterPages/FooterPages';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import parse from 'html-react-parser';
import Banner from '../../components/Banner/Banner';
import DirectorCard from '../../components/DirectorCard/DirectorCard';
import PropBar from '../../components/PropBar/ProbBar';
import { useEffect } from 'react';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const About = () => {
  const config = useConfig();
  const { height, width } = useWindowDimensions();

  useEffect(()=>{
    document.title = "Lighthill Law - About";
  },[])

  return(
    <div className='about'>
      <TopbarPages />   
      <Container>
        <Banner title={'About Us'} />
        <Row
          xs={1}
          sm={1}
          md={1}
          lg={2}
          className="about__firstRow"
        >
          <Col className="about__firstCol">
            <DirectorCard />
          </Col>
          <Col className="about__secondCol">
            {
              parse(config?.about ? config?.about : "")
            }
          </Col>
        </Row>
        <Row className="about__secondRow" style={width < 800 ? {width:"103vw"} : {width:"101vw"}}>
          <FooterPages />
          <PropBar />
        </Row>
      </Container>
    </div>
  )
}

export default About;