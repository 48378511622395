import './contactInformation.css';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { useConfig } from '../../context/configContext';
import Container from 'react-bootstrap/esm/Container';
import FaxSharpIcon from '@mui/icons-material/FaxSharp';

const ContactFax = () => {
  const config = useConfig();
  return(
    <Container>
      <Row>
        <Col
          lg={2}
          md={2}
          sm={2}
          xl={2}
          xs={2}
          xxl={2}
        >
          <FaxSharpIcon fontSize='large' className='contactInformation__icon' />
        </Col>
        <Col>
          <Container>
            <Row>
              { config?.fax }
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  )
}

export default ContactFax;