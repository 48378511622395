import { useState, useEffect, useContext } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Topbar from '../../components/Navbar/Navbar';
import './home.css';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useConfig } from '../../context/configContext';
import PropBar from '../../components/PropBar/ProbBar';

const Home = () => {
  const config = useConfig();
  const { height, width } = useWindowDimensions();

  useEffect(()=>{
    document.title = "Lighthill Law - Home";
  },[])

  return(
    <div 
      className='home'
      style={{
        backgroundImage: `url(${config?.images?.basePath}${config?.images?.homeBackground})`
      }}
    >
      {
        width <= 992
        &&
        <div className='mobile-cover' ></div>
      }
      <Topbar />
      <Container fluid>
        <Row 
        style={
          width <= 992 && height <= 600
          ? 
          { height: '10px'}
          :
          { height: '45vh'}
        }>
          <Col lg={8}></Col>
          <Col className="bg-text" lg={4}> 
            { 
              width >= 992 
              ? 
              config?.slogan
              :
              width <= 992 && height <= 415 && config?.slogan
            } </Col>
        </Row>
        <Row>
          <Col lg={8}></Col>
          <Col className="bg-text" lg={4}>
            { 
              width < 992 && height >= 415
              && 
              config?.slogan
            }</Col>
        </Row>
      </Container>
        <div
          style={{
            position: 'absolute',
            left: 0,
            bottom: 0,
            width: '100%'
          }}
        >
          <PropBar />
        </div>
    </div>
  )
}

export default Home;