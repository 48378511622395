import './services.css';
import TopbarPages from '../../components/NavbarPages/NavbarPages';
import FooterPages from '../../components/FooterPages/FooterPages';
import Container from 'react-bootstrap/esm/Container';
import Banner from '../../components/Banner/Banner';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import parse from 'html-react-parser';
import { useConfig } from '../../context/configContext';
import LigButton from '../../components/Button/LigButton';
import { useParams } from 'react-router';
import { useState, useEffect } from 'react';
import Nav from 'react-bootstrap/Nav';
import PropBar from '../../components/PropBar/ProbBar';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const Services = () => {
  const config = useConfig();
  const params = useParams();
  const [type, setType] = useState("");
  const [idx, setIdx] = useState(0);
  const { height, width } = useWindowDimensions();
  
  useEffect(()=>{
    const type = params.type;
    setType(type);

    const e = (service) => service.name == type;
    setIdx(config?.services?.findIndex(e));

    document.title = `Lighthill Law - Services - ${type}`;
  },[params])

  return(
    <div className='services'>
      <TopbarPages />
      <Container>
        <Banner title={'Services'} />
        <Row
          xs={1}
          sm={1}
          md={1}
          lg={2}
          className="services__firstRow"
        >
          <Col 
            className="services__firstCol"
            lg={3}
          >
            <Container className="services__navCotainer" >
              <Nav 
                defaultActiveKey={`#/services/${config?.services?.[0]}`}
                className="flex-column services__nav"
              >
                {
                  config?.services?.map((service, index)=>
                      <Nav.Link 
                        href={`#/services/${service.name}`}
                        key={index}
                      >
                        <LigButton
                          title={service.name}
                          active={index == idx ? true : false }
                          key={index}
                        />
                      </Nav.Link>
                  )
                }
              </Nav>
            </Container>
          </Col>
          <Col className = "services__secondCol" style={width <= 990 ? {width:"100vw", marginTop:"-90px"} : {}}>
              <Row className="margin-bottom" >
                <h2>{ config?.services?.[idx]?.name }</h2>
              </Row>
              <Row style={{width: "105%"}}>
                {
                  parse(config?.services?.[idx]?.description ? config?.services?.[idx]?.description : "")
                }
              </Row>
          </Col>
        </Row>
        <Row className = "services__secondRow" style={width < 800 ? {width:"103vw"} : {width:"101vw"}}>
          <FooterPages />
          <PropBar />
        </Row>
      </Container>
    </div>
  )
}

export default Services;