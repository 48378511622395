import './propBar.css';
import { useConfig } from '../../context/configContext';
import parse from 'html-react-parser';
import useWindowDimensions from '../../hooks/useWindowDimensions';

const PropBar = () => {
  const { height, width } = useWindowDimensions();
  const config = useConfig();
  return(
    <div className='propBar' style={{
      backgroundColor: config?.tertiaryColor,
    }}>
      <div className='propBar__container' >
        <span>{ parse(config?.legal?.copyRight ? config?.legal?.copyRight : "") }</span>
        <span>{ parse(config?.legal?.privacyPolicy ? config?.legal?.privacyPolicy : "") }</span>
        {
          width >= 730
          &&
          <>
            <span>{ parse(config?.legal?.photo ? config?.legal?.photo : "") }</span>
          </>
        }

      </div>
    </div>
  )
}

export default PropBar;