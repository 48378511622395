import './navLinks.css';
import { useConfig } from '../../context/configContext';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';

const NavLinks = ({type}) => {
  const config = useConfig();
  return(
    <Nav>
      <Nav.Link className={type === 'pages' ? 'nav-text-page' : 'nav-text'} href="#/about">About</Nav.Link>
      <NavDropdown title="Legal Services" id={type === 'pages' ? "basic-nav-dropdown-page" : "basic-nav-dropdown"}>
        {
          config?.services?.map((service, index)=>
            <NavDropdown.Item 
              href={`#/services/${service.name}`}
              key={index}
            >
              { service.name }
            </NavDropdown.Item>
          )
        }
        {
          config?.clientPortal
          &&
          <>
            <NavDropdown.Divider />
            <NavDropdown.Item href="#/client">Client Portal</NavDropdown.Item>
          </>
        }
      </NavDropdown>
      <Nav.Link className={type === 'pages' ? 'nav-text-page' : 'nav-text'} href="#/contact">Contact</Nav.Link>
    </Nav>
  )
}

export default NavLinks;