export const validate_name = (name, type) => {
  if(name.match(/^[A-Za-z]+$/))
    return false;
  return { error: "Name Error", type: type, message:"Name can only contain lowercase and uppercase letters"};
};
export const validate_email = (email) => {
  if(email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/))
    return false;
  return { error: "Email Error", type: 'email', message:"Not a valid email"};
};
export const validate_phone = (phone) => {
  phone = phone.replace(/\s/g, '');
  if(phone.match(/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/))
    return false;
  return { error: "Phone Error", type: 'phone', message:"Not a valid phone number" };
};
export const validate_message = (message) => {
  if(!message)
    return { error: "Message Error", type: 'message', message:"Message cannot be blank" };
  return false;
};