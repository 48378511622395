import './contactModal.css';
import { useConfig } from '../../context/configContext';
import Modal from 'react-bootstrap/Modal';
import LigButton from '../Button/LigButton';

const ContactModal = ({show, handleClose, error}) => {
  const config = useConfig();
  return(
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{ error ? config?.contactModal?.errorTitle : config?.contactModal?.title }</Modal.Title>
      </Modal.Header>
      <Modal.Body>{ error ? config?.contactModal?.error : config?.contactModal?.body }</Modal.Body>
      <Modal.Footer>
        <LigButton title={config?.contactModal?.button} onClick={handleClose} dark={true} />
      </Modal.Footer>
    </Modal>
  )
}

export default ContactModal;