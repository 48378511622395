import './contactForm.css';
import { useConfig } from '../../context/configContext';
import Form from 'react-bootstrap/Form';
import LigButton from '../Button/LigButton';
import { useState } from 'react';
import { validate_email, validate_message, validate_name, validate_phone } from '../../helpers';
import { apiRequest } from '../../requestMethods';

const ContactForm = ({handleShow}) => {
  const config = useConfig();
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState(false);
  const [contactForm, setContactForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    message: ""
  });

  const getFirstname = (e) => {
    const form = { ...contactForm };
    form.firstname = e;
    setContactForm(form);
    setFirstLoad(false);
  }

  const getLastname = (e) => {
    const form = { ...contactForm };
    form.lastname = e;
    setContactForm(form);
    setFirstLoad(false);
  }

  const getEmail = (e) => {
    const form = { ...contactForm };
    form.email = e;
    setContactForm(form);
    setFirstLoad(false);
  }

  const getPhone = (e) => {
    const form = { ...contactForm };
    form.phone = e;
    setContactForm(form);
    setFirstLoad(false);
  }

  const getMessage = (e) => {
    const form = { ...contactForm };
    form.message = e;
    setContactForm(form);
    setFirstLoad(false);
  }

  const submitForm = () => {
    //1. validate
    const validationArray = [];
    validationArray.push(validate_name(contactForm?.firstname, 'firstname'));
    validationArray.push(validate_name(contactForm?.lastname, 'lastname'));
    validationArray.push(validate_email(contactForm?.email));
    validationArray.push(validate_phone(contactForm?.phone));
    validationArray.push(validate_message(contactForm?.message));

    const error = validationArray.find((validationError)=>{
      if(validationError) return "Error!!!";
    })

    if(error)
      setError(error);
    else{
      //2. mailTo -> email
      const postEmail = async () => {
        try {
          const res = await apiRequest.post("/send-email", contactForm);
          console.log("%cSuccess Config","color:green;font-size:24px;",res.data)
          //3. clear form
          setContactForm({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: ""
          })
          setFirstLoad(true);
          //4. show modal with confirmation
          handleShow(false);
        } catch (err) {
          console.log("%cERROR","color:red;font-size:24px;",err);
          //3. clear form
          setContactForm({
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            message: ""
          })
          setFirstLoad(true);
          //4. show modal with confirmation
          handleShow(true);
        }
      };
      postEmail();
    }
  }

  return(
    <Form className="contactForm" >

      <Form.Group 
        className="mb-3 contactForm__alignLeft contactForm__width45" 
        controlId=""
      >
        <Form.Label className="contactForm__label" >First Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="First Name" 
          className={`contactForm__input ${validate_name(contactForm?.firstname, 'firstname') ? firstLoad ? '' : 'is-invalid' : 'is-valid'}`}
          value={contactForm.firstname}
          onChange={(e)=>getFirstname(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {
            error?.type === 'firstname'
            &&
            error.message
          }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
        className="mb-3 contactForm__alignLeft contactForm__width45" 
        controlId="formBasicEmail"
      >
        <Form.Label className="contactForm__label">Last Name</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="Last Name" 
          className={`contactForm__input ${validate_name(contactForm?.lastname, 'lastname') ? firstLoad ? '' : 'is-invalid' : 'is-valid'}`}
          value={contactForm.lastname}
          onChange={(e)=>getLastname(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {
            error?.type === 'lastname'
            &&
            error.message
          }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
        className="mb-3 contactForm__alignLeft contactForm__width100" 
        controlId="formBasicEmail"
      >
        <Form.Label className="contactForm__label">Email</Form.Label>
        <Form.Control 
          type="email" 
          placeholder="Email" 
          className={`contactForm__input ${validate_email(contactForm?.email) ? firstLoad ? '' : 'is-invalid' : 'is-valid'}`}
          value={contactForm.email}
          onChange={(e)=>getEmail(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {
            error?.type === 'email'
            &&
            error.message
          }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
        className="mb-3 contactForm__alignLeft contactForm__width100" 
        controlId="formBasicEmail"
      >
        <Form.Label className="contactForm__label">Phone Number</Form.Label>
        <Form.Control 
          type="text" 
          placeholder="0450 000 000" 
          className={`contactForm__input ${validate_phone(contactForm?.phone) ? firstLoad ? '' : 'is-invalid' : 'is-valid'}`}
          value={contactForm.phone}
          onChange={(e)=>getPhone(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {
            error?.type === 'phone'
            &&
            error.message
          }
        </Form.Control.Feedback>
      </Form.Group>

      <Form.Group 
        className="mb-3 contactForm__alignLeft contactForm__width100 contactForm__height180" 
        controlId="formBasicEmail"
      >
        <Form.Label className="contactForm__label">Message</Form.Label>
        <textarea 
          className={`form-control contactForm__input contactForm__height140 ${validate_message(contactForm?.message) ? firstLoad ? '' : 'is-invalid' : 'is-valid'}`}
          placeholder="message"
          value={contactForm.message}
          onChange={(e)=>getMessage(e.target.value)}
        />
        <Form.Control.Feedback type='invalid'>
          {
            error?.type === 'message'
            &&
            error.message
          }
        </Form.Control.Feedback>
      </Form.Group>

      <LigButton title="submit" dark={true} onClick={()=>submitForm()} disabled={firstLoad ? true : false} />
    </Form>
  )
}

export default ContactForm;