import './ligButton.css';
import Button from 'react-bootstrap/esm/Button';
import { useConfig } from '../../context/configContext';

const LigButton = ({title, active, dark, onClick, disabled}) => {
  const config = useConfig();
  return(
    <Button 
      className="ligButton" 
      variant='outline-primary'
      active={ active }
      style={
        dark 
        && 
        { 
          "--primary-color": config?.primaryColor,
          backgroundColor: 'var(--primary-color)',
          color: 'white'
        } 
      }
      onClick={()=>onClick()}
      disabled={disabled}
    >
      { title }
    </Button>
  )
}

export default LigButton;