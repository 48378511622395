import FooterPages from '../../components/FooterPages/FooterPages';
import TopbarPages from '../../components/NavbarPages/NavbarPages';
import './client.css';

const Clients = () => {
  return(
    <div className='clients'>
      <TopbarPages />
      <h1>Clients</h1>
      <FooterPages />
    </div>
  )
}

export default Clients;