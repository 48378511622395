import React from 'react';

const ConfigContext = React.createContext(null);
const useConfig = () => React.useContext(ConfigContext);

const ConfigProvider = ({ value, children }) => {
  return (
    <ConfigContext.Provider value={value}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigProvider, useConfig };